import React from "react"
import SEO from "../../../components/Layout/Seo"
import tw from "twin.macro"
import styled from "@emotion/styled"

import imageBgFotos from "../../../images/fondo_factura.svg"
import imageBackground from "../../../images/background1.png"

import imageBackground2 from "../../../images/mini-bg-1.png"
import SectionCard from "../../../components/Card/SectionCard"
import { StaticImage } from "gatsby-plugin-image"
import WindowContact from "../../../components/Layout/WindowContact"
import YouTube from "react-youtube"
import FAIcon from "../../../components/Layout/FAIcon"
import { Link, graphql } from "gatsby"
import LayoutPage from "../../../components/global/Layout"
import { PlanDescription } from "../../../components/plans/PlanDescription"
import { MoreInfo } from "../../../components/Layout/MoreInfo"
import { PlanPaymentMethods } from "../../../components/plans/PlanPaymentMethods"
import { PlanSuggest } from "../../../components/plans/PlanSuggest"
import { YoutubeSection } from "../../../components/Layout/YoutubeSection"
import CountryLink from "../../../components/Layout/CountryLink"

const Button = styled.button(() => [
  `
@media (min-width: 768px) {
	max-width: 300px;
}
@media (min-width: 1024px) {
	max-width: 300px;
}
width: 100%;
margin-top: 1.25rem;
margin-left: auto;
margin-right: auto;
color: white;
background-color: #646fff;
transition: background-color 0.7s ease;
padding-top: 0.25rem;
padding-bottom: 0.25rem;

&:hover {
	background-color: #8d94f4;
}
-webkit-box-shadow: 0 0 10px 1px #646fff;
box-shadow: 0 0 10px 1px #646fff;
`,
  tw`px-8 py-3 text-base rounded-ultra `,
])

const IndexPage = () => {



  return (
    <>
      <div
        style={{
          backgroundImage: `url(${imageBgFotos})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "calc(100vh -  0px)",
        }}
      >
        <LayoutPage>

          <div css={tw` min-h-screen flex     mx-auto max-w-content px-8  `}>
            <div
              css={tw`grid lg:grid-cols-2 mt-0 lg:my-auto  pt-0  lg:text-left text-center  `}
            >
              <div css={tw`lg:pr-24 mt-0 lg:my-auto   `}>
                <h1
                  css={tw`text-3xl lg:text-4xl mt-10 lg:pt-0 pt-150px lg:mt-0 text-autogestiones-card   `}
                >
                  Aceptá como método de pago{" "}
                  <span className="text-gradient-secondary" css={tw`font-bold`}>
                    MODO
                  </span>
                </h1>
                <p
                  css={tw`text-gray-300 text-base  lg:text-base  font-normal w-full    pt-4`}
                >
                   Llegó MODO a Autogestiones, MODO es la primera billetera que implementa, junto a los bancos, descuentos para las compras de tus clientes, aprovechando reintegros inmediatos
                </p>

                <div css={tw`   my-0  `}>
                  <CountryLink
                    to="/servicios/autogestiones-cloud"
                    style={{
                      textDecoration: `none`,
                    }}
                  >
                    <Button>Más información</Button>
                  </CountryLink>
                </div>
                <p css={tw`text-sm mt-4 text-gray-400`}>
                  *Incluído en AutoCaja.
                </p>
              </div>
              <div css={tw`mt-4 lg:mt-0 lg:block hidden `}>
                <StaticImage
                  src="../../../images/logo-color/i-modo.png"
                  width={1150}
                  quality={100}
                  layout="fullWidth"
                  placeholder="none"
                  formats={["auto", "webp", "avif"]}
                  alt="pantalla de Modo"
                />{" "}
              </div>
            </div>
          </div>
          <div
            css={tw`  w-full absolute mt-0 top-0 h-screen hidden  pointer-events-none lg:flex  `}
            style={{
              zIndex: 3,
              backgroundImage: `url(${imageBackground})`,
              backgroundSize: "100% ",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
            }}
          ></div>

          <div
            css={tw`  w-full absolute -mt-8 lg:mt-0 top-0 h-screen pointer-events-none lg:hidden flex   `}
            style={{
              zIndex: 3,
              backgroundImage: `url(${imageBackground2})`,
              backgroundSize: "100% ",
              backgroundRepeat: "no-repeat",
              fontSize: "inherit",
              textDecoration: "none",
              backgroundPosition: "center bottom",
            }}
          ></div>
         {/*  <div
            css={tw`w-full  -mt-8 lg:mt-0  px-4 py-5 `}
            style={{
              borderBottomRightRadius: "24%",
              borderBottomLeftRadius: "24%",
              background: "#33366d",
            }}
          >
            <YoutubeSection videoId="xIQijf9WjpU" />
          </div> */}
           
         
        </LayoutPage>
      </div>
    </>
  )
}

export default IndexPage

export const Head = () =>
  <SEO
    lang="es-AR"
    title="Integraciones | MODO"
    description="Cobrá con la integración de Autogestiones con MODO y mejora la gestión de tu venta por AutoCaja"
  />

